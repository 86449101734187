import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Modal,
  Row,
  Col,
  Container,
  Card,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FullSoonLogo from "assets/images/fullsoon_logo_1.svg";
import "./index.scss";
import moment from "moment";
import { useLoading } from "contexts/LoadingContextManagement";

function Text({ caption, style }) {
  const customStyle = { ...style, border: "none", width: "auto", paddingLeft: "0" };
  return (
    <span style={customStyle} className="add-restaurants-input form-control bg-transparent">
      {caption}
    </span>
  );
}

function OrderPdfModal({ onHide, show, data: { selectedIngredient }, temperature }) {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const pdfRef = useRef();
  
  // Calculate total price from labo_items
  const totalPrice = selectedIngredient?.labo_items?.reduce(
    (total, item) => total + ((item.product_quantity * item.unit_price) + (item.unit_tax || 0)),
    0
  )?.toFixed(2);

  // This function captures the PDF content and adds pages if necessary.
  const generatePdf = () => {
    setLoading(true)
    const input = pdfRef.current;
    // Increase scale for better resolution if needed
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // Create a new jsPDF instance with A4 dimensions in mm
      const pdf = new jsPDF("p", "mm", "a4");
      const margin = 10;
      const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
      const pdfHeight = pdf.internal.pageSize.getHeight() - margin * 2;

      // Calculate image height based on the ratio
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const ratio = pdfWidth / canvasWidth;
      const imgHeight = canvasHeight * ratio;
      
      let heightLeft = imgHeight;
      let position = margin;

      // Add the first page
      pdf.addImage(imgData, "PNG", margin, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;

      // While there is content beyond the current page, add a new page and repeat
      while (heightLeft > 0) {
        position = (heightLeft - imgHeight) + margin;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", margin, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
      }
      pdf.save(`labo-order-${selectedIngredient?.restaurant?.name}.pdf`);
      setLoading(false)
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-pdf-modal"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
      </Modal.Header>

      <Modal.Body>
        <Container style={{ maxWidth: "90%" }}>
          <div className="modal-header">
            <h3>{t("Delivery note from a lab to a restaurant")}</h3>
            <Button onClick={generatePdf} variant="primary">
              {t("Download")}
            </Button>
          </div>
          {/* Wrap your content in a container with padding (margins) for PDF */}
          <Card ref={pdfRef} className="modal-card-container" style={{ padding: "10mm" }}>
            <div className="logo-image" style={{ marginLeft: "26px"}}>
              <img src={FullSoonLogo} alt="logo" />
            </div>
            <div className="modal-sub-container">
              <div className="left-side">
                <div className="date-container">
                  <div style={{ fontSize: "21px", fontWeight: "bold" }}>
                    {t("Order date")} : <span style={{ color: "#8232FF" }}>
                      {moment(selectedIngredient?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  <div style={{ fontSize: "21px", fontWeight: "bold" }}>
                    {t("Delivery date")} : <span style={{ color: "#8232FF" }}>
                      {moment(selectedIngredient?.send_date).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
                <Card className="labo-container-modal" style={{ marginTop: selectedIngredient.delivery_temperature_celcius ? "43px" : "" }}>
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                      {t("Labo Details")}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input">{t("Name")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.name} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Phone")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.phone_number} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Mail")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.email} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Address")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.address} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("SIRET Number")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.siret_number} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Contact name")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient?.labo_restaurant?.invoice?.contact_name} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              <div className="right-side">
                <Card className="temerature-container">
                  <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                    {t("Delivery temperature")}
                  </div>
                  <div style={{ fontSize: "27px", display: "flex", alignItems: "end", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold", lineHeight: "15px" }}>°C</span>
                    {selectedIngredient.delivery_temperature_celcius ? (
                      <span style={{ lineHeight: "22px" }}>
                        {selectedIngredient.delivery_temperature_celcius}&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : temperature ? (
                      <span>{temperature}&nbsp;&nbsp;&nbsp;</span>
                    ) : ""}
                    {selectedIngredient.delivery_temperature_celcius || temperature ? (
                      <span style={{ lineHeight: "20px" }}>---------</span>
                    ) : null}
                  </div>
                </Card>
                <Card className="pos-container">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                      {t("Customer details")}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input">{t("First name / Last name")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.contact_name} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("SIRET Number")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.siret_number} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Mail")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.email} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Address")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.address} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Phone")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.phone_number} />
                      </Col>
                      <Col lg={{ span: 5, offset: 1 }} className="p-0">
                        <span className="add-restaurants-input ps-0">{t("Restaurant")}</span>
                        <Text style={{ width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}
                          caption={selectedIngredient.restaurant?.invoice?.name} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Card className="delivery-container">
              <Card.Header className="delivery-header">
                <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold" }}>
                  {t("Delivery details")}
                </div>
              </Card.Header>
              <Card.Body className="delivery-body">
                <Row className="mt-4">
                  <Col lg={12}>
                    <div className="p-4 allergens-container">
                      <div className="row custom-table h-100">
                        <div className="col-lg-12 h-100 p-0">
                          <div className="tablescroll">
                            <table className="table bg-transparent">
                              <thead>
                                <tr>
                                  <td style={{ width: "20%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Reference")}
                                  </td>
                                  <td className="text-center" style={{ width: "35%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Product")}
                                  </td>
                                  <td className="text-center" style={{ width: "30%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Packagings")}
                                  </td>
                                  <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Quantity")}
                                  </td>
                                  <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Unit Price")}{t(" (HT)")}
                                  </td>
                                  <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Total Price")}{t(" (EXCL. VAT)")}
                                  </td>
                                  <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("VAT")}
                                  </td>
                                  <td className="text-center" style={{ width: "25%", fontSize: "13px", fontWeight: "bold", color: "#8232FF" }}>
                                    {t("Total Price")}{t(" (INCL. VAT)")}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedIngredient?.labo_items.map((item) => (
                                  <tr key={item.provider_reference_number} className="">
                                    <td style={{ height: "50px" }}>
                                      <Text style={{ width: "100%", display: "inline-block", whiteSpace: "initial" }}
                                        caption={item.provider_reference_number} />
                                    </td>
                                    <td className="text-center" style={{ width: "200px" }}>
                                      <Text style={{ overflow: "hidden" }} caption={item.provider_reference_name} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={item.conditioning} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={item.product_quantity} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={item.unit_price} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={(item.unit_price * item.product_quantity)?.toFixed(2)} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={(item?.unit_tax || 0)} />
                                    </td>
                                    <td className="text-center" style={{ width: "100px" }}>
                                      <Text caption={((item.unit_price * item.product_quantity) + (item?.unit_tax || 0))?.toFixed(2)} />
                                      </td>
                                    </tr>)) }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{
                height: "100px",
                width: "100%",
                backgroundColor: "#8232FF",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                color: "white",
                paddingRight: "50px"
              }}>
                <span>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    <sub>{t("Total")} <sub>{t(" (INCL. VAT)")}</sub></sub>
                    <span style={{ fontSize: "32px", fontWeight: "bold" }}>{totalPrice}</span>
                    <sub>€</sub>
                  </span>
                </span>
              </Card.Footer>
                
            </Card>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default OrderPdfModal;
